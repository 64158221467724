<template>
  <div class="animated fadeIn container">
    <h1>发送结果</h1>
    <b-input-group class="mt-4" style="max-width: 300px" v-if="this.$store.state.isAdmin">
      <b-form-input
        type="text"
        class="form-control"
        placeholder="会员名字或账号"
        v-model="searchKeyword"
        @keydown.native="searchEmailInputKeyDown"
      />
      <b-btn variant="primary" class="fl" @click.prevent="changeSearchKeyword">查找</b-btn>
    </b-input-group>
    <b-input-group class="mt-4" v-show="!this.$store.state.isAdmin">
      <b-btn class="mr-2 fl" v-if="user ? user.canDownload : null" variant="primary" @click="requestAllAttachments"
        >발송결과 전체 내려받기</b-btn
      >
      <b-btn variant="danger" class="fl" @click="deleteAllSmsRequests">删除所有发送结果</b-btn>
      <b-btn variant="warning" class="fl ml-2" @click="deleteSmsRequests">删除选择的发送结果</b-btn>
    </b-input-group>
    <p v-if="!isLoading" class="mt-4">
      总共找到 <span class="color-skyblue font-weight-bold">{{ totalCount }}</span> 条结果
    </p>
    <pulse-loader :loading="isLoading" :color="loaderStyle.color" :size="loaderStyle.size"></pulse-loader>
    <table class="border-table hover-table" v-show="!isLoading">
      <thead>
        <tr>
          <td v-show="!$store.state.isAdmin" width="60px" class="text-center">选择</td>
          <td width="60px"></td>
          <td width="80px" v-show="this.$store.state.isAdmin">名字</td>
          <td width="120px">发送时间</td>
          <td>内容</td>
          <td class="text-center" width="300px">状态</td>
        </tr>
      </thead>
      <tbody>
        <tr :key="item.id" v-for="(item, index) in items">
          <td v-show="!$store.state.isAdmin" class="text-center">
            <input type="checkbox" v-model="selectedRequests[index]" />
          </td>
          <td>
            <img :src="getFlagImage(item.providerId)" style="height: 30px" :title="item.id" />
          </td>
          <!-- <td v-show="$store.state.isAdmin">
            <span class="mr-3 text-center">{{ item.id }}</span>
          </td> -->
          <td v-show="$store.state.isAdmin" @click="pushUserInfo(item.userId)">
            <span class="mr-3 text-center">{{ item.userEmail }}</span>
          </td>
          <td @click="pushSmsRequest(item.id)">
            <span class="mr-3">{{ item.createdAt | dateFormat }}</span>
          </td>
          <td @click="pushSmsRequest(item.id)">
            {{ item.message }}
          </td>
          <td @click="pushSmsRequest(item.id)">
            <span v-if="successCount(item) == item.totalCount" class="float-right mr-2"> 처리완료 </span>
            <span v-else class="float-right mr-2">
              发送 <span class="color-skyblue">{{ item.totalCount }}</span> / 成功
              <span class="color-skyblue">{{ item.deliveredCount }}</span> / 等待
              <span class="color-skyblue">{{ item.waitCount }}</span> / 失败
              <span class="color-skyblue">{{ item.deliveryFailedCount }}</span>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <b-pagination
      align="center"
      :total-rows="totalCount"
      v-model="currentPage"
      :per-page="10"
      :limit="10"
      class="mt-4"
      @change="changePage"
    ></b-pagination>
  </div>
</template>
<script>
import SmsService from '@/services/SmsService'
import moment from 'moment'

import { PulseLoader } from 'vue-spinner/dist/vue-spinner.min.js'
import { mapGetters } from 'vuex'

export default {
  name: 'smsSend',
  components: {
    PulseLoader,
  },
  data: () => {
    return {
      isLoading: true,
      loaderStyle: {
        color: '#ccc',
        size: '8px',
      },
      items: [],
      limit: 10,
      totalCount: Number.MAX_SAFE_INTEGER,
      currentPage: null,
      userId: null,
      searchKeyword: '',
      selectedRequests: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  created() {
    this.searchKeyword = this.$route.query.searchKeyword
    this.userId = this.$route.query.userId
    this.currentPage = parseInt(this.$route.query.page) || 1
    this.getRequests()
  },
  mounted() {
    document.getElementsByClassName('app-body')[0].classList.add('bg-white')
  },
  methods: {
    async getRequests() {
      this.selectedRequests = []
      this.isLoading = true

      var params = {
        searchKeyword: this.searchKeyword,
        page: this.currentPage,
        limit: this.limit,
      }

      if (this.userId) {
        params.userId = this.userId
      }

      const response = await SmsService.getRequests(params)
      this.isLoading = false

      if (response.status === 200 && response.data) {
        this.items = response.data.list
        this.totalCount = response.data.totalCount
      }
    },
    async deleteSmsRequests() {
      var deleteItems = []
      for (var i = 0, l = this.items.length; i < l; i++) {
        const request = this.selectedRequests[i]
        if (request === true) {
          deleteItems.push(this.items[i].id)
        }
      }
      if (deleteItems.length == 0) {
        alert('삭제할 발송을 선택해주세요')
        return
      }

      if (!confirm('삭제한 발송 결과는 복구할 수 없습니다. 선택된 발송결과를 삭제하시겠습니까?')) {
        return
      }

      await SmsService.deleteSmsRequests({ ids: deleteItems.join(',') })
      this.currentPage = 1
      this.getRequests()
    },
    async deleteAllSmsRequests() {
      if (!confirm('삭제한 발송 결과는 복구할 수 없습니다. 발송결과를 모두 삭제하시겠습니까?')) {
        return
      }

      await SmsService.deleteSmsRequests()
      this.currentPage = 1
      this.getRequests()
    },
    paidMoney: function (count) {
      return parseFloat(count) * 19.8
    },
    pushSmsRequest: function (requestId) {
      this.$router.push({
        path: `/smsRequests/${requestId.toString()}`,
      })
    },
    pushUserInfo: function (userId) {
      this.$router.push({
        path: `/admin/users/${userId}`,
      })
    },
    successCount: function (item) {
      if (!item || !item.unit) {
        return 0
      }
      var count = 0
      var units = item.unit
      for (var i = 0; i < units.length; i++) {
        const unit = units[i]
        if (unit.status == 3) count += unit.count
      }
      return count
    },
    requestAllAttachments: function () {
      SmsService.getAllAttachment(this.user.id).then(response => {
        const url = window.URL.createObjectURL(new Blob(['\ufeff', response.data]))
        const link = document.createElement('a')
        link.href = url

        link.setAttribute('download', 'results_' + moment().format('YYYYMMDDHHmm') + '.csv')
        document.body.appendChild(link)
        link.click()
      })
    },
    changePage(page) {
      var query = {
        page: page,
      }

      if (this.userId) {
        query.userId = this.userId
      }

      if (this.searchKeyword) {
        query.searchKeyword = this.searchKeyword
      }

      this.$router.push({
        path: '/smsRequests',
        query,
      })
    },
    changeSearchKeyword() {
      this.$router.push({
        path: '/smsRequests',
        query: {
          searchKeyword: this.searchKeyword,
        },
      })
    },
    searchEmailInputKeyDown(e) {
      if (e.which === 13) {
        this.changeSearchKeyword()
      }
    },
  },
  watch: {
    '$route.query.userId'() {
      this.userId = this.$route.query.userId
      this.getRequests()
    },
    '$route.query.page'() {
      this.currentPage = this.$route.query.page
      this.getRequests()
    },
    '$route.query.searchKeyword'() {
      this.searchKeyword = this.$route.query.searchKeyword
      this.getRequests()
    },
    items: function () {},
    user() {},
  },
}
</script>
